<template>
  <nav
    class="navbar navbar-expand-lg navbar-primary bg-primary px-0 py-0 position-sticky top-0"
  >
    <!-- Navbar toggle -->
    <a href="/"
      ><img class="navbar-brand h-12 ps-4" src="../assets/img/pnaulogo.png"
    /></a>
    <button
      class="navbar-toggler collapsed"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarToggle"
      aria-controls="navbarToggle"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
    <!-- Collapse -->
    <div class="collapse navbar-collapse" id="navbarToggle">
      <!-- Nav -->
      <div class="navbar-nav mx-lg-auto"></div>
      <!-- Right navigation -->
      <div class="navbar-nav ms-lg-4">
        <a class="nav-item nav-link text-white" href="#music">MUSIC</a>
        <a class="nav-item nav-link text-white" href="#tickets">TICKETS</a>
        <a class="nav-item nav-link text-white" href="#merch">MERCH</a>
        <div class="social-links d-block d-sm collapse text-center pt-6 pb-6">
          <a
            class="text-white p-4"
            href="https://www.instagram.com/pnaupnau"
            target="_blank"
            rel="noopener"
            ><i class="bi bi-instagram"></i
          ></a>
          <a
            class="text-white p-4"
            href="https://open.spotify.com/artist/6n28c9qs9hNGriNa72b26u"
            target="_blank"
            rel="noopener"
            ><i class="bi bi-spotify"></i
          ></a>
          <a
            class="text-white p-4"
            href="https://www.facebook.com/pnaupnau"
            target="_blank"
            rel="noopener"
            ><i class="bi bi-facebook"></i
          ></a>
          <a
            class="text-white p-4"
            href="https://twitter.com/pnau"
            target="_blank"
            rel="noopener"
            ><i class="bi bi-twitter"></i
          ></a>
          <a
            class="text-white p-4"
            href="https://www.youtube.com/watch?v=9aI9sRyOhMc&list=PLFMVTe06MwvDwwdUSAHaVjQC0HMoB8vyQ"
            target="_blank"
            rel="noopener"
            ><i class="bi bi-youtube"></i
          ></a>
        </div>
      </div>
    </div>
  </nav>
</template>
