<template>
  <section class="redbackground"> 
    <div class="col-12 col-md-5 col-lg-5 container pt-10 pb-10">
          <img alt="pnau-tour-2024" src="../assets/img/pnau-tour-2024.jpeg" class="img-fluid shadow-6 rounded-4" />
        
    </div>
    </section>




  <section class="greenbackground" id="tickets">
    <div class="container pb-8">
      <p class="pt-12 pb-2 pnausubtext">PNAU TOUR DATES</p>
      
    </div>

    <div class="d-flex justify-content-center">
  <div class="table-responsive">
    <table class="table table-hover table-spaced">
      <tbody>
        <div class="d-flex justify-content-center">
  <div class="table-responsive">
    <table class="table table-hover table-spaced">
      <tbody>
        <tr>
          <td class="date-column">
            <a class="pnausubtext3">Fri Nov 22nd</a>
          </td>
          <td class="event-column">
            <a class="pnausubtext3">Pridefest Live</a>
          </td>
          <td class="location-column">
            <a class="pnausubtext3">PERTH</a>
          </td>
          <td class="action-column text-end">
            <a href="https://www.pridewa.com.au/event/pridefest-live/" class="btn btn-secondary rounded-pill mb-1">BUY</a>
          </td>
        </tr>

        <tr>
          <td class="date-column">
            <a class="pnausubtext3">Thu Nov 28th</a>
          </td>
          <td class="event-column">
            <a class="pnausubtext3">Dee Why RSL</a>
          </td>
          <td class="location-column">
            <a class="pnausubtext3">SYDNEY</a>
          </td>
          <td class="action-column text-end">
            <a href="https://tickets.oztix.com.au/outlet/event/80dd087a-a195-49d4-806c-ebf8b20592c3" class="btn btn-secondary rounded-pill mb-1">BUY</a>
          </td>
        </tr>

        <tr>
          <td class="date-column">
            <a class="pnausubtext3">Fri Nov 29th</a>
          </td>
          <td class="event-column">
            <a class="pnausubtext3">Drifters Wharf</a>
          </td>
          <td class="location-column">
            <a class="pnausubtext3">GOSFORD</a>
          </td>
          <td class="action-column text-end">
            <a href="https://drifterswharf.oztix.com.au/outlet/event/20604f29-1ca2-49a6-8022-40d3885dbcc0" class="btn btn-secondary rounded-pill mb-1">BUY</a>
          </td>
        </tr>

        <tr>
          <td class="date-column">
            <a class="pnausubtext3">Sat Nov 30th</a>
          </td>
          <td class="event-column">
            <a class="pnausubtext3">Waves</a>
          </td>
          <td class="location-column">
            <a class="pnausubtext3">Wollongong</a>
          </td>
          <td class="action-column text-end">
            <a href="https://moshtix.com.au/v2/event/pnau-w-special-guests/171622" class="btn btn-secondary rounded-pill mb-1">BUY</a>
          </td>
        </tr>

        <tr>
          <td class="date-column">
            <a class="pnausubtext3">Sun Dec 1st</a>
          </td>
          <td class="event-column">
            <a class="pnausubtext3">Riviera Beach Club</a>
          </td>
          <td class="location-column">
            <a class="pnausubtext3">St Kilda</a>
          </td>
          <td class="action-column text-end">
            <a href="https://tickets.oztix.com.au/outlet/event/d0b71afe-e9f3-4e46-bafe-1ed34cf1e494" class="btn btn-secondary rounded-pill mb-1">BUY</a>
          </td>
        </tr>

        <tr>
          <td class="date-column">
            <a class="pnausubtext3">Thu Dec 5th</a>
          </td>
          <td class="event-column">
            <a class="pnausubtext3">The Station</a>
          </td>
          <td class="location-column">
            <a class="pnausubtext3">Sunshine Coast</a>
          </td>
          <td class="action-column text-end">
            <a href="https://moshtix.com.au/v2/event/pnau-w-special-guests/171624" class="btn btn-secondary rounded-pill mb-1">BUY</a>
          </td>
        </tr>

        <tr>
          <td class="date-column">
            <a class="pnausubtext3">Sat Dec 7th</a>
          </td>
          <td class="event-column">
            <a class="pnausubtext3">Miami Marketta</a>
          </td>
          <td class="location-column">
            <a class="pnausubtext3">Gold Coast</a>
          </td>
          <td class="action-column text-end">
            <a href="https://tickets.oztix.com.au/outlet/event/44506e87-8f71-46d2-b81a-7024d67c6bdd" class="btn btn-secondary rounded-pill mb-1">BUY</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
    </tbody>
  </table>
</div>
</div>

  </section>

  <hr />


  <section class="greenbackground2" id="music">
    <div class="container">
      <div class="">
        <a
          href="https://geo.music.apple.com/au/artist/pnau/5555346?mt=1&app=music"
          style="
            display: inline-block;
            overflow: hidden;
            background: url(https://tools.applemusic.com/en-us/badge-lrg.svg?kind=artist&bubble=apple_music)
              no-repeat;
            width: 158px;
            height: 45px;
          "
        ></a>
        <div class="d-flex justify-content-center pb-8">
          <iframe
            src="https://open.spotify.com/embed/playlist/7GL0bXeNzmDex9JAtXDa2I"
            width="75%"
            height="380"
            frameborder="0"
            allowtransparency="true"
          ></iframe>
        </div>
      </div>
    </div>
  </section>
  <hr />


  <section class="greenbackground2" id="merch">
    <div class="container">
      <p class="pnausubtext pt-8">MERCH</p>
      <div class="pt-8">
        <a href="https://pnau.bandcamp.com/merch" target="_blank" class="btn btn-secondary rounded-pill mb-4">
          BUY MERCH
        </a>
      </div>

      
      <a href="https://pnau.bandcamp.com/merch" target="_blank"
        ><img
          class="w-2/5"
          src="../assets/img/pnau_neon_1024x1024.png"
          alt="PNAU Merch - Green"
      /></a>
    </div>
  </section>
</template>
